@import 'styles/variables';

.sidebarContainer {
    &.setSidebarVisibility {
        z-index: 1500;
        .drawer {
            & > div {
                border-right: 0px;
            }
        }
    }

    .drawer {
        position: relative;
        display: flex;

        & > div {
            transition: width 200ms ease-in-out;
            border-right: 1px solid $grey_65;
            top: 65px;
            height: calc(100vh - 65px);
            display: flex;
            flex-direction: column;
            overflow-x: hidden;
            overflow-y: auto;
            -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
            scrollbar-width: none; /* Hide scrollbar for Firefox */
        }
    }

    .drawer > div::-webkit-scrollbar {
        display: none; /* Hide scrollbar track */
    }

    .drawerOpen {
        width: 286px;
        background-color: $grey_110;
    }

    .drawerClose {
        width: 60px;
        background-color: $grey_110;
    }

    .sidebarToggle {
        z-index: 50;
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        height: 54px;
        width: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $grey_95;
        border-radius: 8px 0px 0px 8px;
        cursor: pointer;
        border-right-width: 0px;
        border-left-width: 0px;
        min-width: auto;

        &:focus-visible {
            outline: 2px solid $grey_60;
            outline-offset: '-2px';
        }

        .arrowRight {
            transition: all 200ms ease-in-out;
            color: $grey_50;

            &--expanded {
                transition: all 200ms ease-in-out;
                color: $grey_50;
                transform: rotate(180deg);
            }
        }
    }

    .drawerListItems {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .menuTitle {
        padding-left: 15px;
        margin-top: 21px;
        height: 20px;
        font-size: 12px;
        font-weight: 600;
        color: #d8d9dd;
    }

    .listItem {
        cursor: pointer;
        margin-bottom: 14px;

        .listItem__icon {
            color: #8a92a3;
            min-width: 24px;
            margin-right: 20px;
            transition: color 200ms ease-in-out;
        }

        .listItem__text {
            height: 24px;
            margin: 0;

            span {
                font: 600 16px $monserrat;
                line-height: 26px;
                color: $grey_90;
                white-space: nowrap;
                transition: color 200ms ease-in-out;
            }
        }

        &:hover,
        &.active {
            .listItem__icon {
                color: $grey_50;
            }

            .listItem__text {
                span {
                    color: $grey_50;
                }
            }
        }

        &:focus-visible {
            background-color: $grey_100;
            color: $grey_50;
        }

        &.disabled {
            opacity: 0.5;
            cursor: default;

            .listItem__icon {
                color: $grey_99 !important;
            }

            .listItem__text {
                span {
                    color: $grey_99 !important;
                }
            }
        }
    }

    .profileInfo {
        flex-grow: 1;
        position: relative;
    }
}
