// Fonts
$monserrat: Montserrat, sans-serif;
$roboto: Roboto, sans-serif;

// Colors
// - primary colors
$primary_red: #9e2339;
$primary_black: #000;
// - secondary colors
$cyan: #96e0ff;
$teal: #006781;
$mint: #48cfa0;
$purple: #53469c;
// - action colors
$action_color: #0075aa;
// - grey scale colors
$grey_110: #1d1d1f;
$grey_105: #3a404c;
$grey_100: #43464a;
$grey_99: #535362;
$grey_95: #565961;
$grey_90: #757982;
$grey_80: #a8a8ac;
$grey_70: #dadad6;
$grey_65: #e5e5e5;
$grey_60: #f2f2f2;
$grey_50: #edecf1;

// Breakpoints
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
