@font-face {
    font-family: Montserrat;
    src: local(Montserrat),
        url('../assets/fonts/montserrat/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: Montserrat;
    src: local(Montserrat),
        url('../assets/fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: Montserrat;
    src: local(Montserrat),
        url('../assets/fonts/montserrat/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: Montserrat;
    src: local(Montserrat),
        url('../assets/fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: Roboto;
    src: local(Montserrat), url('../assets/fonts/roboto/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
}

@font-face {
    font-family: Roboto;
    src: local(Montserrat), url('../assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: Roboto;
    src: local(Montserrat), url('../assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: Roboto;
    src: local(Montserrat), url('../assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: Roboto;
    src: local(Montserrat), url('../assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
}
