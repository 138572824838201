@import 'variables.scss';

body {
    margin: 0;
    font-family: $monserrat;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    overflow: hidden;

    .mainContainer {
        flex-grow: 1;
        margin-left: 60px;
        background: #edecf1;
        overflow-x: auto;
        overflow-y: hidden;
    }
}

input {
    caret-color: #643bc4;
}

.MuiModal-root {
    z-index: 1400 !important;
}

.MuiSnackbar-root {
    z-index: 1450;
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #565961 #fcfcfc;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: #fcfcfc;
}

*::-webkit-scrollbar-thumb {
    background-color: #565961;
    border-radius: 20px;
    border: 3px solid #fcfcfc;
}

::selection {
    background: #e8e2f6;
    color: #372c80;
}

/* Media query for screens smaller than 1300 */
@media (max-width: 1300px) {
    .responsive_grid_1300 {
        width: 1300px;
    }
}

@media (max-width: 1919px) {
    .responsive_grid_1920 {
        width: 1300px;
    }
}

.final-review .editor-hidden-link {
    text-decoration: none;
    user-select: none;
    pointer-events: none;
    color: black;
    background-color: white;
}
